<template v-if="formActive">
    <FormItem id="name" :label="$t('overview.name')" v-model="tarifflevel.name" :required="true" />
    <FormItem id="order" :label="$t('overview.order')" v-model="tarifflevel.order_by" :required="true" type="number" />
    <FormItem id="colour" :label="$t('navigation.colours')" v-model="tarifflevel.colour" type="color" />
</template>

<script>
    export default {
        name: 'TarifflevelForm',
        props: ['itemData'],
        data() {
            return {
                tarifflevel: {
                    'name': null,
                    'colour': null,
                    'order_by': null
                },
                submitted: false,
                loading: false,
                invalid: {},
                formActive: false
            }
        },
        watch: {
            itemData: function (val) {
                this.tarifflevel = val    
            },
        },
        methods: {
            active(value){
                this.formActive = value;
            },
            validate(){
                let tarifflevel = this.tarifflevel;
                let valid = true;

                let required = document.querySelectorAll('#formModal input[required]');

                for(let field of required) {
                    if(!field.value || field.value == this.$t('form.select')) {
                        field.classList.add('is-invalid');
                        valid = false;
                    }
                    else{
                        field.classList.remove('is-invalid');
                    }
                }
                if(valid) valid = tarifflevel.name != null && tarifflevel.name != "" && tarifflevel.order_by != null && tarifflevel.order_by != "";
                if(!valid) alert(this.$t('errors.empty_fields'));
                return valid;
            },
            getData() {
                return this.tarifflevel;
            },
            setData(data) {
                this.tarifflevel = data
            },
            reset() {
                this.tarifflevel = {
                    'name': null,
                    'colour': null,
                    'order_by': null
                },
                this.loading = false
                this.invalid = {}
            }
        }
    }
</script>
